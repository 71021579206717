<template>
    <v-form>
        <v-list-item>
            <v-combobox
                v-model="public_sector.name"
                :items="establishments"
                :loading="loading"
                :disabled="loading"
                :error-messages="!validate(public_sector.name, true) ? ['valid [Name Of Establishment] is required '] : []"
                name="Establishment Name"
                label="Establishment"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-combobox
                v-model="public_sector.doctor_name"
                :loading="loading"
                :disabled="loading"
                :items="doctors"
                :error-messages="!validate(public_sector.doctor_name, true) ? ['valid [Doctor Name] is required '] : []"
                type="text"
                label="Doctor Name"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-combobox
                v-model="public_sector.alternative_doctor_name"
                :error-messages="!validate(public_sector.alternative_doctor_name, false) ? ['valid [Alternativ Doctor Name] is required '] : []"
                :loading="loading"
                :items="doctors"
                :disabled="loading"
                label="Alternativ Doctor Name"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-radio-group :loading="loading" :disabled="loading" v-model="public_sector.classification" label="Classification" required row>
                <v-radio label="A" color="red" value="A"></v-radio>
                <v-radio label="B" color="primary" value="B"></v-radio>
                <v-radio label="C" color="success" value="C"></v-radio>
            </v-radio-group>
        </v-list-item>
        <v-list-item>
            <v-autocomplete
                v-model="public_sector.speciality"
                :loading="loading"
                :disabled="loading"
                :items="specialities"
                :error-messages="!validate(public_sector.speciality, true, 1) ? ['valid [Speciality] is required '] : []"
                type="text"
                label="Speciality"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-autocomplete 
                v-model="public_sector.products" 
                :loading="loading" 
                :disabled="loading" 
                multiple 
                chips
                :error-messages="!products_length ? ['valid [Products] is required '] : []"
                type="text" 
                label="Product" 
                color="primary" 
                :items="products_names"
            />
        </v-list-item>
        <v-list-item>
            <v-combobox
                v-model="public_sector.city"
                :loading="loading"
                :disabled="loading"
                :items="cities"
                @change="update_regions"
                :error-messages="!validate(public_sector.city, true) ? ['valid [City] is required '] : []"
                label="City"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-combobox
                v-model="public_sector.region"
                :loading="loading"
                :disabled="loading"
                :items="regions"
                :error-messages="!validate(public_sector.region, true) ? ['valid [Region] is required '] : []"
                label="Region"
                color="primary"
            />
        </v-list-item>  
        <v-list-item>
            <v-text-field
                v-model="public_sector.comment"
                :error-messages="!validate(public_sector.comment, false, 1) ? ['[Comment] is not valid '] : []"
                type="text"
                :loading="loading"
                :disabled="loading"
                label="Comment"
                color="primary"
            />
        </v-list-item>
    </v-form>
</template>

<script>
export default {
    props: {
        public_sector: {
            type: Object,
            required: true,
        },
    },
    methods: {
        validate(value, required = false, length = 3) {
            return (typeof value == "string" && value?.length >= length) || (!required && value?.length == 0);
        },
        async update_regions(v){
            try {
                console.log("updating regions", v)
                this.regions.splice(0)
                if(!v){return}
                const regions = (await this.$api.post("/address/region/fetch/names_by_city", {
                    city_name:v
                })).data.result.names
                this.regions.push(...regions)
            } catch (error) {
                console.log(error)
            }
        }
    },
    data() {
        return {
            establishments: [],
            specialities: [],
            doctors: [], 
            cities: [], 
            regions: [], 
            products_names: [], 
            loading: false, 
        };
    },
    async mounted() {
        try {
            const establishments = (await this.$api.post("/fetch/establishments")).data.results
            this.establishments.splice(0)
            this.establishments.push(...establishments)


            const doctors = (await this.$api.post("/doctor/fetch/names")).data.result.names
            this.doctors.splice(0)
            this.doctors.push(...doctors)

            const specialities = (await this.$api.post("/speciality/fetch/names")).data.result.names
            this.specialities.splice(0)
            this.specialities.push(...specialities)

            const cities = (await this.$api.post("/address/city/fetch/names")).data.result.names
            this.cities.splice(0)
            this.cities.push(...cities)

            const products_names = (await this.$api.post("/mr/products/fetch/names")).data.result.names
            this.products_names.splice(0)
            this.products_names.push(...products_names)
        } catch (error) {
            console.log(error)
        }
    },
    computed: {
        valid() {
            let condition = true;
            condition &= this.validate(this.public_sector.name, true);
            condition &= this.validate(this.public_sector.doctor_name, true);
            condition &= this.validate(this.public_sector.alternative_doctor_name, false);
            condition &= this.validate(this.public_sector.classification, true, 1);
            condition &= this.validate(this.public_sector.speciality, true, 1);
            condition &= this.validate(this.public_sector.city, true);
            condition &= this.validate(this.public_sector.region, true);
            condition &= this.validate(this.public_sector.comment, false, 1);
            condition &= !!this.public_sector?.products?.length;
            this.$set(this.public_sector, "valid", condition);
            return condition;
        },
        products_length(){
            return !!this.public_sector?.products?.length
        }
    },
    watch: {
        valid() {},
    },
};
</script>

<style></style>
